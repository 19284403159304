<template>
    <ComingSoon />
</template>

<script>
import ComingSoon from '@/components/ComingSoon/index';

export default {
    name: 'protectionFixedValue',
    components: {
        ComingSoon
    }
};
</script>
